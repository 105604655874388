import { useQuery } from "@tanstack/react-query";
import { Alert, AlertDescription } from "components/ui/alert";
import { AlertTriangle } from "lucide-react";
import { appConfig } from "../config";
import { LinkedinRefreshButton } from "./linkedin-refresh-button";

export const LinkedinRefreshAlert = ({
	userId,
	t,
}: { userId: string; t: (key: string) => string }) => {
	const { data: lastUpdateData } = useQuery({
		queryKey: ["linkedinLastUpdate", userId],
		queryFn: async () => {
			const response = await fetch(
				`${appConfig.API_MAIN_URL}/api/linkedin/get/last-update?userId=${userId}`,
			);
			if (!response.ok) {
				throw new Error("Error fetching last update");
			}
			return response.json();
		},
		enabled: !!userId,
	});

	const isUpdateNeeded = () => {
		if (!lastUpdateData?.updated_at) return false;
		const lastUpdate = new Date(lastUpdateData.updated_at);
		const twoHoursAgo = new Date(Date.now() - 2 * 60 * 60 * 1000);
		return lastUpdate < twoHoursAgo;
	};

	if (!isUpdateNeeded()) return null;
	return (
		<Alert
			variant="default"
			className="flex flex-col items-start my-4 border-orange-200 bg-orange-100 text-orange-800"
		>
			<div className="flex items-center justify-between w-full">
				<div className="flex items-center gap-2">
					<AlertTriangle className="h-4 w-4" />
					<AlertDescription className="text-start">
						{t(
							"content-creation.blog_post_from_multimedia.multimedia_upload.update_needed",
						)}
					</AlertDescription>
				</div>
				<LinkedinRefreshButton />
			</div>
		</Alert>
	);
};

import type { BlockNoteEditor } from "@blocknote/core";
import { useCreateBlockNote } from "@blocknote/react";
import {
	keepPreviousData,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { toast, useToast } from "components/hooks/use-toast";
import { AuthContext, useNavigate } from "components/lib";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "components/ui/accordion";
import { Button } from "components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "components/ui/collapsible";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { FileUploader } from "components/ui/file-uploader";
import { FormField, FormItem } from "components/ui/form";
import { Input } from "components/ui/input";
import MultiStepForm, {
	type MultiFormStep,
} from "components/ui/multi-step-form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { ES, US } from "country-flag-icons/react/3x2";
import { diffWords } from "diff";
import { useAnalytics } from "lib/analytics";
import { htmlToGutenbergBlocks, interactWithExtension } from "lib/utils";
import {
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	Copy,
	Plus,
	Trash2,
} from "lucide-react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
	type Control,
	type FieldErrors,
	type UseFormRegister,
	type UseFormSetValue,
	useFieldArray,
	useWatch,
} from "react-hook-form";
import type { AuthContextType } from "types/authContext";
import { appConfig } from "views/content-creation/config";
import { z } from "zod";
import type { GetMorePostsFetchType, LinkedinPostType } from "../../../types";
import { LinkedinRefreshAlert } from "../../linkedin-refresh-alert";
import { LinkedinRefreshButton } from "../../linkedin-refresh-button";
import TextEditorWithReadability from "../../text-editor-with-benchmarks";
import LinkedinPost from "../BlogFromMultimedia/linkedin-post";
import type { WordPressCredentials as WordPressCredentialsType } from "../WordpressBlogFromLinkedinPost/types";
import {
	WordPressCredentials,
	type WordpressCredentialsState,
} from "../WordpressBlogFromLinkedinPost/wordpress-credentials";
import type { WordPressUser } from "../WordpressBlogFromLinkedinPost/wordpress-users";

// Add this utility function at the top of the file, outside of any component
function stripHtml(html: string): string {
	const tmp = document.createElement("div");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
}

const mode = import.meta.env.MODE as "development" | "production";

const Step1Schema = z
	.object({
		files: z.array(z.instanceof(File)).optional(),
		text: z.string().optional(),
		url: z.string().url().optional().or(z.literal("")),
		youtubeUrl: z
			.string()
			.url()
			.regex(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/)
			.optional()
			.or(z.literal("")),
		linkedinPosts: z.array(z.string()).optional(),
		personalOpinion: z.string().optional(),
		domain: z
			.object({
				id: z.number(),
				domain: z.string(),
				organizationId: z.string().nullable(),
			})
			.optional(),
		wpUser: z
			.object({
				id: z.string(),
				username: z.string(),
				name: z.string(),
				email: z.string().nullable(),
				userId: z.string().nullable(),
			})
			.optional(),
		language: z.enum(["es", "en"]).default("es"),
	})
	.refine(
		(data) =>
			(data.files?.length ?? 0) > 0 ||
			(data.text?.length ?? 0) > 0 ||
			(data.url?.length ?? 0) > 0 ||
			(data.youtubeUrl?.length ?? 0) > 0 ||
			(data.linkedinPosts?.length ?? 0) > 0,
		{
			message:
				"At least one file, some text, a YouTube URL, a website URL or a Linkedin Post is required",
			path: ["files"],
		},
	);

const Step2Schema = z.object({
	outline: z
		.array(
			z.object({
				title: z.string().min(1, "Outline item cannot be empty"),
				level: z.enum(["2", "3"]),
			}),
		)
		.min(1, "At least one outline item is required"),
	keywords: z.array(z.string()).min(1, "At least one keyword is required"),
	combinedContent: z.string().min(1, "Please send the blog content"),
	externalDomainPostsData: z.array(
		z.object({
			url: z.string(),
			data: z.array(z.string()),
			domain: z.string(),
			blogConcept: z.string(),
		}),
	),
	internalDomainPostsData: z.array(
		z.object({
			url: z.string(),
			data: z.array(z.string()),
			domain: z.string(),
			blogConcept: z.string(),
		}),
	),
	useEfficientModel: z.boolean().optional(),
});

const Step3Schema = z.object({
	content: z.string().min(1, "Please review and edit your blog post"),
	readabilityMetrics: z
		.array(
			z.object({
				name: z.string(),
				description: z.string(),
				value: z.number(),
				maxValue: z.number().optional(),
			}),
		)
		.optional(),
	generatedBlog: z.string(),
	startTime: z.number().optional(),
	wordpressCredentials: z.array(
		z.object({
			applicationPassword: z.string(),
			username: z.string(),
		}),
	),
	editor: z.any(),
});

type OutlineData = {
	outline: Array<{
		title: string;
		level: "2" | "3";
	}>;
	keywords: string[];
	combinedContent: string;
	externalDomainPostsData: Array<{
		url: string;
		data: string[];
		domain: string;
		blogConcept: string;
	}>;
	internalDomainPostsData: Array<{
		url: string;
		data: string[];
		domain: string;
		blogConcept: string;
	}>;
};

// Add this type definition at the top of the file
type AllFormData = z.infer<typeof Step1Schema> &
	z.infer<typeof Step2Schema> &
	z.infer<typeof Step3Schema>;

type Editor = {
	blocksToHTMLLossy: (doc: Document) => Promise<string>;
	document: Document;
};

type Domain = {
	id: number;
	domain: string;
	organizationId?: string;
};

const tryPublishWithCredentials = async (
	editor: BlockNoteEditor,
	previousValues: { [key: number]: OutlineData },
	credentials: { applicationPassword: string; username: string }[],
	domain: Domain & { domain: string },
	wpUser: WordPressUser & { id: string; userId: string },
	attemptIndex = 0,
) => {
	if (attemptIndex >= 3 || attemptIndex >= credentials.length) {
		throw new Error(
			"Failed to publish after trying multiple WordPress credentials",
		);
	}

	try {
		const html = await editor.blocksToHTMLLossy(editor.document);
		const blockContent = htmlToGutenbergBlocks(html);
		const title = previousValues[1]?.outline[0]?.title ?? "Blog post";
		const contentWithOutTitle = blockContent.replace(title, "");
		const wpResponse = await axios.post("/api/wordpress/publish-blogpost", {
			content: contentWithOutTitle,
			title,
			excerpt: "",
			slug: title.toLowerCase().replace(/\s+/g, "-"),
			domain: domain.domain,
			wordpress_application_password_key:
				credentials[attemptIndex].applicationPassword,
			wordpress_username: credentials[attemptIndex].username,
			status: "draft",
			wordpressUser: wpUser,
			creation_time: new Date().toISOString(),
			author: wpUser.id,
			date_gmt: new Date().toISOString(),
			generated_by: "amplification",
			user_id: wpUser.userId,
			organization_id: domain.organizationId ?? "",
		});

		if (!wpResponse.data?.id) {
			throw new Error("No post ID received from WordPress");
		}

		const blogUrl = `https://${domain.domain}/wp-admin/post.php?post=${wpResponse.data.id}&action=edit`;
		window.open(blogUrl, "_blank");
		return wpResponse;
	} catch (error) {
		if (axios.isAxiosError(error) && error.response?.status === 401) {
			return tryPublishWithCredentials(
				editor,
				previousValues,
				credentials,
				domain,
				wpUser,
				attemptIndex + 1,
			);
		}
		throw error;
	}
};

type StepData =
	| z.infer<typeof Step1Schema>
	| z.infer<typeof Step2Schema>
	| z.infer<typeof Step3Schema>;

const steps: MultiFormStep<
	typeof Step1Schema | typeof Step2Schema | typeof Step3Schema,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	any[]
>[] = [
	{
		id: "step_1",
		name: "Amplification Blog - Upload Multimedia",
		schema: Step1Schema,
		fn: async (data) => {
			try {
				const formData = new FormData();
				if ("files" in data && data.files) {
					for (const file of data.files) {
						formData.append("files", file);
					}
				}
				if ("text" in data && data.text) {
					formData.append("text", data.text);
				}
				if ("url" in data && data.url) {
					formData.append("url", data.url);
				}
				if ("youtubeUrl" in data && data.youtubeUrl) {
					formData.append("youtubeUrl", data.youtubeUrl);
				}
				if ("linkedinPosts" in data && data.linkedinPosts) {
					for (const post of data.linkedinPosts) {
						formData.append("linkedinPosts", post);
					}
				}
				if ("domain" in data && data.domain) {
					formData.append("domain", data.domain.domain);
					formData.append("domainId", data.domain.id.toString());
				}

				if ("wpUser" in data && data.wpUser) {
					formData.append("wpUser", JSON.stringify(data.wpUser));
				}

				let language = "es";
				if ("language" in data && data.language) {
					language = data.language;
					formData.append("language", language);
				}

				const response = await axios.post(
					"/api/content-creation/amplification/step1",
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					},
				);

				if (!response.data) {
					throw new Error("No data received from server");
				}

				return {
					outline: response.data.outline,
				};
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw new Error(
						error.response?.data?.message ||
							`Error processing multimedia ${error}`,
					);
				}
				throw error;
			}
		},
		component: ({ register, errors, control, t, setValue }) => {
			const [showEditor, setShowEditor] = useState(false);
			const [page, setPage] = useState(0);
			const [totalPosts, setTotalPosts] = useState<LinkedinPostType[]>([]);
			const [selectedPosts, setSelectedPosts] = useState<LinkedinPostType[]>(
				[],
			);
			const [selectedLanguage, setSelectedLanguage] = useState<"es" | "en">(
				"es",
			);

			const auth = useContext<AuthContextType>(AuthContext);
			const navigate = useNavigate();
			const queryClient = useQueryClient();

			const userId = auth?.user?.id;
			const count = 3;

			const fetchLinkedinPostsFromDB = async ({
				userId,
				page,
				count,
			}: {
				userId: string;
				page: number;
				count: number;
			}) => {
				try {
					const response = await fetch(
						`${appConfig.API_MAIN_URL}/api/linkedin/posts?userId=${userId}&count=${count}&page=${page}`,
					);

					if (!response.ok) {
						throw new Error("Error fetching linkedin posts");
					}

					const posts: GetMorePostsFetchType = await response.json();
					return posts;
				} catch (error) {
					throw new Error("Error fetching linkedin posts");
				}
			};

			const { data, isError, isFetching, isPlaceholderData } = useQuery({
				queryKey: ["linkedinPosts", page, userId],
				queryFn: () => {
					if (userId) {
						return fetchLinkedinPostsFromDB({
							userId,
							page,
							count,
						});
					}
					return Promise.resolve({ posts: [], hasMore: false });
				},
				enabled: !!userId,
			});

			useEffect(() => {
				setTotalPosts([]);
				const invalidateQueries = async () => {
					await queryClient.invalidateQueries({
						queryKey: ["linkedinPosts"],
					});
				};
				invalidateQueries();
			}, [queryClient]);

			useEffect(() => {
				if (data?.posts) {
					setTotalPosts((prevPosts) => [...prevPosts, ...data.posts]);
				}
			}, [data?.posts]);

			const handlePostSelect = (post: LinkedinPostType) => {
				setSelectedPosts((prevSelected) => {
					const isAlreadySelected = prevSelected.some(
						(p) => p.linkedin_id === post.linkedin_id,
					);
					if (isAlreadySelected) {
						return prevSelected.filter(
							(p) => p.linkedin_id !== post.linkedin_id,
						);
					}
					return [...prevSelected, post];
				});
			};

			// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
			useEffect(() => {
				if (Object.keys(errors).length > 0) {
					console.error("Errores de validación:", errors);

					// Mostrar mensajes de error específicos
					if ("files" in errors) {
						toast({
							title: "Error en archivos",
							description:
								errors.files?.message || "Error con los archivos subidos",
							variant: "destructive",
						});
					}

					if ("url" in errors) {
						toast({
							title: "Error en URL",
							description:
								errors.url?.message || "La URL ingresada no es válida",
							variant: "destructive",
						});
					}

					if ("youtubeUrl" in errors) {
						toast({
							title: "Error en URL de YouTube",
							description:
								errors.youtubeUrl?.message || "La URL de YouTube no es válida",
							variant: "destructive",
						});
					}

					if ("domain" in errors) {
						toast({
							title: "Error en dominio",
							description: errors.domain?.message || "El dominio es requerido",
							variant: "destructive",
						});
					}

					if ("wpUser" in errors) {
						toast({
							title: "Error en usuario WordPress",
							description:
								errors.wpUser?.message ||
								"El usuario de WordPress es requerido",
							variant: "destructive",
						});
					}

					if ("outline" in errors) {
						toast({
							title: "Error en el esquema",
							description:
								errors.outline?.message || "El esquema del blog tiene errores",
							variant: "destructive",
						});
					}

					if ("content" in errors) {
						toast({
							title: "Error en el contenido",
							description:
								errors.content?.message ||
								"El contenido del blog tiene errores",
							variant: "destructive",
						});
					}
				}
			}, [errors, toast]);

			useEffect(() => {
				setValue(
					"linkedinPosts",
					selectedPosts.map((post) => post.content),
				);
			}, [selectedPosts, setValue]);

			useEffect(() => {
				setValue("language", selectedLanguage);
			}, [selectedLanguage, setValue]);

			return (
				<>
					<div className="mb-6">
						<LinkedinRefreshAlert userId={userId ?? ""} t={t} />
					</div>
					<div className="mb-6">
						{/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
						<label className="block text-sm font-medium mb-2">
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.language",
							)}
						</label>
						<div className="flex gap-4">
							<button
								type="button"
								onClick={() => setSelectedLanguage("es")}
								className={`flex items-center gap-2 p-2 rounded-md border transition-all ${
									selectedLanguage === "es"
										? "border-primary bg-primary/10"
										: "border-gray-200 hover:border-primary/50"
								}`}
							>
								<ES className="w-8 h-8" />
								<span className="text-sm font-medium">
									{t(
										"content-creation.blog_post_from_multimedia.multimedia_upload.spanish",
									)}
								</span>
							</button>
							<button
								type="button"
								onClick={() => setSelectedLanguage("en")}
								className={`flex items-center gap-2 p-2 rounded-md border transition-all ${
									selectedLanguage === "en"
										? "border-primary bg-primary/10"
										: "border-gray-200 hover:border-primary/50"
								}`}
							>
								<US className="w-8 h-8" />
								<span className="text-sm font-medium">
									{t(
										"content-creation.blog_post_from_multimedia.multimedia_upload.english",
									)}
								</span>
							</button>
						</div>
					</div>
					<span className="block text-xs text-right opacity-50">
						{t(
							"content-creation.blog_post_from_multimedia.multimedia_upload.supported_file_types",
						)}
					</span>
					<FormField
						control={control}
						name="files"
						render={({ field }) => (
							<FormItem>
								<FileUploader
									value={field.value}
									onValueChange={field.onChange}
									maxFiles={4}
									maxSize={4 * 1024 * 1024}
									accept={{
										"text/plain": [".txt"],
										"application/pdf": [".pdf"],
										"audio/mpeg": [".mp3"],
										"audio/ogg": [".ogg"],
										"audio/x-m4a": [".m4a"],
									}}
								/>
								{"files" in errors && (
									<p className="col-span-full mt-2 text-sm text-red-400">
										{errors.files?.message}
									</p>
								)}
							</FormItem>
						)}
					/>
					<div className="mt-2 w-full">
						<Textarea
							{...register("text")}
							placeholder={t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.type_or_paste_text",
							)}
							className="mt-2 w-full"
						/>
					</div>
					<Input
						{...register("url")}
						placeholder={t(
							"content-creation.blog_post_from_multimedia.multimedia_upload.enter_website_url",
						)}
						className="mt-2 w-full"
					/>
					{"url" in errors && (
						<p className="mt-2 text-sm text-red-400">{errors.url?.message}</p>
					)}
					<Input
						{...register("youtubeUrl")}
						placeholder={t(
							"content-creation.blog_post_from_multimedia.multimedia_upload.enter_youtube_url",
						)}
						className="mt-2 w-full"
					/>
					{"youtubeUrl" in errors && (
						<p className="mt-2 text-sm text-red-400">
							{errors.youtubeUrl?.message}
						</p>
					)}
					<div className="flex items-center justify-start">
						<h3 className="mt-4">
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.latest_linkedin_posts",
							)}
						</h3>
						<LinkedinRefreshButton />
					</div>
					{totalPosts.length > 0 ? (
						<>
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
								{totalPosts.map((post) => (
									<LinkedinPost
										key={`${post.linkedin_id}`}
										post={post}
										isSelected={selectedPosts.some(
											(p) => p.linkedin_id === post.linkedin_id,
										)}
										onSelect={handlePostSelect}
									/>
								))}
							</div>

							<div className="flex justify-center mt-8">
								<Button
									variant="outline"
									className="bg-white"
									onClick={() => {
										if (!isPlaceholderData && data?.hasMore) {
											setPage((old) => old + 1);
										}
									}}
									disabled={isFetching || isPlaceholderData || !data?.hasMore}
								>
									{isFetching
										? t("content-creation.blog_post_from_multimedia.loading")
										: data?.hasMore
											? t(
													"content-creation.blog_post_from_multimedia.multimedia_upload.show_more",
												)
											: t(
													"content-creation.blog_post_from_multimedia.multimedia_upload.no_more_posts",
												)}
								</Button>
							</div>
						</>
					) : (
						!isFetching && (
							<p className="mt-4">
								{t(
									"content-creation.blog_post_from_multimedia.multimedia_upload.no_linkedin_posts_available",
								)}
							</p>
						)
					)}

					{isError && (
						<div className="mt-4 text-center text-red-500">
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.error_fetching_posts",
							)}
						</div>
					)}
				</>
			);
		},
	},
	{
		id: "step_2",
		name: "Amplification Blog Outline",
		schema: Step2Schema,
		fn: async (data, allData) => {
			try {
				if (
					"outline" in data &&
					"keywords" in data &&
					"combinedContent" in data &&
					"externalDomainPostsData" in data &&
					"internalDomainPostsData" in data &&
					"useEfficientModel" in data
				) {
					const language = allData[0].language;
					const domain = allData[0].domain;
					const body = {
						outline: data.outline,
						keywords: data.keywords,
						combinedContent: data.combinedContent,
						externalDomainPostsData: [],
						internalDomainPostsData: [],
						language,
						domain: domain?.domain,
						useEfficientModel: data.useEfficientModel,
						...(allData[0].wpUser?.userId && {
							userId: allData[0].wpUser.userId,
						}),
					};

					const response = await axios.post(
						"/api/content-creation/amplification/step2",
						body,
					);

					if (!response.data) {
						throw new Error("No data received from server");
					}

					if (response.data.code === "GENERATION_IN_PROGRESS") {
						throw new Error("GENERATION_IN_PROGRESS");
					}

					return { htmlContent: response.data.htmlContent };
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status === 429) {
						if (error.response.data?.code === "GENERATION_IN_PROGRESS") {
							throw new Error("GENERATION_IN_PROGRESS");
						}
					}
					throw new Error(
						error.response?.data?.message || "Error processing blog outline",
					);
				}
				throw error;
			}
		},
		component: ({ control, errors, metadata, setValue, t }) => {
			const { fields, append, remove, replace, move } = useFieldArray({
				control: control,
				name: "outline",
			});

			const outlineWatch = useWatch({
				control,
				name: "outline",
			});

			const {
				outline,
				keywords,
				combinedContent,
				externalDomainPostsData,
				internalDomainPostsData,
			} = metadata[0]?.outline as OutlineData;

			const [useEfficientModel, setUseEfficientModel] = useState(true);

			useEffect(() => {
				setValue("useEfficientModel", useEfficientModel);
			}, [useEfficientModel, setValue]);

			useEffect(() => {
				if (fields.length === 0 && outline && outline.length > 0) {
					replace(outline);
				}
				setValue("keywords", keywords);
				setValue("combinedContent", combinedContent);
				setValue("externalDomainPostsData", externalDomainPostsData);
				setValue("internalDomainPostsData", internalDomainPostsData);
			}, [
				outline,
				keywords,
				combinedContent,
				externalDomainPostsData,
				internalDomainPostsData,
				fields.length,
				replace,
				setValue,
			]);

			useEffect(() => {
				if (Object.keys(errors).length > 0) {
					console.error("Form validation errors:", errors);
				}
			}, [errors]);

			const getIndentation = (level: string) => {
				switch (level) {
					case "2":
						return "ml-0";
					case "3":
						return "ml-4";
					default:
						return "ml-0";
				}
			};

			return (
				<>
					<Card className="mx-auto w-full">
						<CardHeader>
							<CardTitle>
								{t(
									"content-creation.blog_post_from_multimedia.blog_outline.title",
								)}
							</CardTitle>
							<CardDescription>
								{t(
									"content-creation.blog_post_from_multimedia.blog_outline.description",
								)}
							</CardDescription>
							<div className="flex items-center mb-4">
								<div className="inline-flex items-center rounded-lg border bg-muted p-1">
									<button
										type="button"
										onClick={() => setUseEfficientModel(true)}
										className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors ${
											useEfficientModel
												? "bg-background text-foreground shadow-sm"
												: "text-muted-foreground hover:text-foreground"
										}`}
									>
										FAST
									</button>
									<button
										type="button"
										onClick={() => setUseEfficientModel(false)}
										className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors ${
											!useEfficientModel
												? "bg-background text-foreground shadow-sm"
												: "text-muted-foreground hover:text-foreground"
										}`}
									>
										QUALITY
									</button>
								</div>
								<p className="text-sm text-muted-foreground ml-4">
									{useEfficientModel
										? "Generates content faster but may be lower quality"
										: "Generates higher quality content but takes longer"}
								</p>
							</div>
						</CardHeader>
						<CardContent>
							<ul className="pl-0 ml-0">
								{fields.map((field, index) => {
									const outlineItem = outlineWatch?.[index]
										? outlineWatch[index]
										: field;
									return (
										<li
											key={field.id}
											className={`flex items-center space-x-1 ${getIndentation(outlineItem?.level || "1")}`}
										>
											<div className="flex flex-col space-y-1">
												<Button
													type="button"
													variant="ghost"
													size="sm"
													onClick={() => move(index, Math.max(0, index - 1))}
													disabled={index === 0}
													className="px-1 py-0 h-6"
												>
													<ChevronUp className="w-4 h-4" />
												</Button>
												<Button
													type="button"
													variant="ghost"
													size="sm"
													onClick={() =>
														move(index, Math.min(fields.length - 1, index + 1))
													}
													disabled={index === fields.length - 1}
													className="px-1 py-0 h-6"
												>
													<ChevronDown className="w-4 h-4" />
												</Button>
											</div>
											<FormField
												control={control}
												name={`outline.${index}.level`}
												render={({ field: levelField }) => (
													<Select
														onValueChange={(value) =>
															levelField.onChange(value)
														}
														value={levelField.value?.toString() || "1"}
													>
														<SelectTrigger icon={false} className="w-[60px]">
															<SelectValue placeholder="Level" />
														</SelectTrigger>
														<SelectContent>
															{[2, 3].map((level) => (
																<SelectItem
																	key={level}
																	value={level.toString()}
																>
																	H{level}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												)}
											/>
											<FormField
												control={control}
												name={`outline.${index}.title`}
												render={({ field: contentField }) => (
													<FormItem className="flex-grow">
														<Input {...contentField} className="w-full" />
													</FormItem>
												)}
											/>
											<Button
												type="button"
												variant="ghost"
												size="sm"
												onClick={() => remove(index)}
												className="px-2"
											>
												<Trash2 className="w-4 h-4 opacity-60" />
											</Button>
										</li>
									);
								})}
							</ul>
							<Button
								type="button"
								onClick={() =>
									append({
										title: "",
										level: "2",
									})
								}
								className="mt-4"
								variant="outline"
							>
								<Plus className="w-4 h-4 mr-2" /> Add Heading
							</Button>
							{"outline" in errors &&
								errors.outline &&
								"root" in errors.outline && (
									<p className="mt-2 text-sm text-red-500">
										{errors.outline.message}
									</p>
								)}

							<Accordion type="single" collapsible className="w-full mt-4">
								<AccordionItem value="keywords">
									<AccordionTrigger className="text-[1.1rem] font-semibold">
										Keywords
									</AccordionTrigger>
									<AccordionContent>
										<div className="space-y-2">
											{keywords.map((keyword, index) => (
												<div key={keyword}>
													<div className="rounded-md border px-4 py-3 font-mono text-sm">
														{keyword}
													</div>
												</div>
											))}
										</div>
										<Button
											type="button"
											onClick={() => setValue("keywords", [...keywords, ""])}
											className="mt-2"
											variant="outline"
										>
											<Plus className="w-4 h-4 mr-2" /> Add Keyword
										</Button>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
				</>
			);
		},
	},
	{
		id: "step_3",
		name: "Review and Edit Amplified Blog",
		schema: Step3Schema,
		fn: async (data: StepData, allData: StepData[], captureEvent) => {
			try {
				const step1Data = allData[0] as z.infer<typeof Step1Schema>;
				if (
					"startTime" in data &&
					"editor" in data &&
					data.startTime &&
					data.editor &&
					step1Data &&
					"domain" in step1Data &&
					"wpUser" in step1Data &&
					step1Data.domain &&
					step1Data.wpUser &&
					step1Data.domain.domain &&
					step1Data.wpUser.id &&
					step1Data.wpUser.userId
				) {
					const endTime = Date.now();
					const timeToEdit = Math.round((endTime - data.startTime) / 1000);
					const modifiedBlog = (data as z.infer<typeof Step3Schema>).content;
					const generatedBlog = (data as z.infer<typeof Step3Schema>)
						.generatedBlog;

					const domain = step1Data.domain;
					const wpUser = step1Data.wpUser;

					const blockContent = htmlToGutenbergBlocks(modifiedBlog);

					const strippedGeneratedBlog = stripHtml(generatedBlog);
					const strippedModifiedBlog = stripHtml(modifiedBlog);

					const changes = diffWords(
						strippedGeneratedBlog,
						strippedModifiedBlog,
					);

					const { wordsAdded, wordsDeleted } = changes.reduce(
						(acc, change) => {
							if (change.added) {
								acc.wordsAdded += change.count || 0;
							} else if (change.removed) {
								acc.wordsDeleted += change.count || 0;
							}
							return acc;
						},
						{ wordsAdded: 0, wordsDeleted: 0 },
					);

					const blogGenerationMetadata = {
						timeToEdit,
						wordsAdded,
						wordsDeleted,
						readabilityMetrics: (data as z.infer<typeof Step3Schema>)
							.readabilityMetrics,
					};

					if (captureEvent) {
						captureEvent("amplification-blog-edited", {
							wordsAdded,
							wordsDeleted,
							timeToEdit,
						});
					}

					const blogGenResponse = await axios.post(
						"/api/blog-post-generations/create-blog",
						{
							generatedBlog: strippedGeneratedBlog,
							modifiedBlog: strippedModifiedBlog,
							metadata: blogGenerationMetadata,
						},
					);

					if (!blogGenResponse.data) {
						throw new Error("Failed to create blog post generation");
					}
					console.log("blogGenResponse", blogGenResponse);
					// Publish to WordPress
					const wpResponse = await tryPublishWithCredentials(
						(data as z.infer<typeof Step3Schema>).editor,
						allData as { [key: number]: OutlineData },
						(data as z.infer<typeof Step3Schema>).wordpressCredentials,
						domain as Domain & { domain: string },
						wpUser as WordPressUser & { id: string; userId: string },
					);

					if (!wpResponse.data?.id) {
						throw new Error("Failed to publish blog post");
					}

					const blogUrl = `https://${domain.domain}/wp-admin/post.php?post=${wpResponse.data.id}&action=edit`;
					window.open(blogUrl, "_blank");
					return { publishedUrl: blogUrl };
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw new Error(
						error.response?.data?.message || "Error processing review",
					);
				}
				throw error;
			}
		},
		component: ({
			metadata,
			register,
			setValue,
			control,
			captureEvent,
			previousValues,
			errors,
			t,
		}) => {
			const htmlContent = metadata[1].htmlContent as string;
			const [currentContent, setCurrentContent] = useState(htmlContent);
			const [wordpressConfig, setWordpressConfig] =
				useState<WordpressCredentialsState | null>(null);
			const { toast } = useToast();
			const editor = useCreateBlockNote();
			const domain = previousValues[0]?.domain as Domain;
			const wpUser = previousValues[0]?.wpUser as WordPressUser;

			const auth = useContext<AuthContextType>(AuthContext);
			const userId = auth?.user?.id;
			const organizationId = auth?.user?.organization_id;

			useEffect(() => {
				setValue("content", currentContent);
				setValue("generatedBlog", currentContent);
			}, [currentContent, setValue]);

			useEffect(() => {
				setValue("editor", editor);
				setValue("content", htmlContent);
				setValue("generatedBlog", htmlContent);
				setValue("startTime", Date.now());
				setCurrentContent(htmlContent);
			}, [editor, htmlContent, setValue]);

			useEffect(() => {
				if (domain && wpUser) {
					setValue("domain", {
						id: domain.id,
						domain: domain.domain,
						organizationId: domain.organizationId || null,
					});
					setValue("wpUser", {
						id: wpUser.id,
						username: wpUser.username,
						name: wpUser.name,
						email: wpUser.email || null,
						userId: wpUser.userId || null,
					});
				}
			}, [domain, wpUser, setValue]);

			const handleContentChange = (text: string) => {
				setCurrentContent(text);
			};

			const handlePublish = async () => {
				if (
					!wordpressConfig ||
					!wordpressConfig.applicationPassword ||
					!wordpressConfig.username ||
					!wordpressConfig.user
				) {
					toast({
						title: "Error",
						description:
							"Faltan datos requeridos en la configuración de WordPress",
						variant: "destructive",
					});
					return;
				}

				try {
					const credentials = [
						{
							applicationPassword: wordpressConfig.applicationPassword,
							username: wordpressConfig.username,
						},
					];

					setValue("wordpressCredentials", credentials);

					const domainData = {
						id: wordpressConfig.domainId,
						domain: wordpressConfig.webUrl,
						organizationId: wordpressConfig.organizationId || "",
					};

					const wpUserData = {
						id: wordpressConfig.user.id,
						username: wordpressConfig.user.username,
						name: wordpressConfig.user.name,
						email: wordpressConfig.user.email || null,
						userId: wordpressConfig.user.userId,
					};

					if (!wpUserData.id || !wpUserData.userId || !domainData.domain) {
						toast({
							title: "Error",
							description: "Faltan datos requeridos del usuario o dominio",
							variant: "destructive",
						});
						return;
					}

					toast({
						title: "Publicando",
						description: "El contenido se está publicando en WordPress...",
					});

					await tryPublishWithCredentials(
						editor,
						previousValues as { [key: number]: OutlineData },
						credentials,
						domainData as Domain & { domain: string },
						wpUserData as WordPressUser & { id: string; userId: string },
					);

					toast({
						title: "Éxito",
						description: "El contenido se ha publicado correctamente",
					});

					setWordpressConfig(null);
				} catch (error) {
					toast({
						title: "Error",
						description: "Error al publicar en WordPress",
						variant: "destructive",
					});
				}
			};

			return (
				<div className="relative">
					<TextEditorWithReadability
						initialContent={htmlContent}
						onContentChange={handleContentChange}
						onReadabilityMetricsChange={(metrics) => {
							setValue("readabilityMetrics", metrics);
							if (captureEvent) {
								const eventData: Record<string, number> = {};
								for (const metric of metrics) {
									eventData[metric.name] = metric.value;
								}
								captureEvent("amplification-blog-generated", eventData);
							}
						}}
						onExportToWordPress={() => {
							setWordpressConfig({} as WordpressCredentialsState);
						}}
					/>

					{wordpressConfig && (
						<Dialog open={!!wordpressConfig} onOpenChange={(open) => !open}>
							<DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
								<DialogHeader>
									<DialogTitle>Publicar en WordPress</DialogTitle>
									<DialogDescription>
										Configura las credenciales para publicar en WordPress
									</DialogDescription>
								</DialogHeader>
								<div className="space-y-6">
									<WordPressCredentials
										userId={userId ?? ""}
										organizationId={organizationId ?? ""}
										setWordpressConfig={setWordpressConfig}
										wordpressConfig={wordpressConfig}
									/>
									<div className="flex justify-end gap-2 pt-4 border-t">
										<Button
											variant="outline"
											onClick={() => setWordpressConfig(null)}
										>
											Cancelar
										</Button>
										<Button
											variant="default"
											className="bg-blue-500 hover:bg-blue-600 text-white"
											onClick={handlePublish}
										>
											Publicar Ahora
										</Button>
									</div>
								</div>
							</DialogContent>
						</Dialog>
					)}
				</div>
			);
		},
	},
];

type Props = {
	t: (key: string) => string;
};

export const AmplificationBlog: React.FC<Props> = ({ t }) => {
	const { toast } = useToast();
	const { captureEvent } = useAnalytics();

	const handleSubmit = useCallback(
		async (
			data: z.infer<
				typeof Step1Schema | typeof Step2Schema | typeof Step3Schema
			>[],
		) => {
			toast({
				title: "Success",
				description:
					"Blog post created successfully! Reload to create another blog.",
			});
		},
		[toast],
	);

	return (
		<div className="mx-auto max-w-5xl">
			<h1 className="mb-5 text-2xl font-bold">
				{t("content-creation.amplification_blog.title")}
			</h1>
			<MultiStepForm
				name="amplification-blog"
				t={t}
				steps={steps}
				onSubmit={handleSubmit}
				captureEvent={captureEvent}
			/>
		</div>
	);
};

export default AmplificationBlog;

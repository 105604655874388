import axios from "axios";
import { appConfig } from "views/content-creation/config";
import type { IcpFormData } from "../schemas";
import type { PostReactionsResponse } from "../types";

const API_URL = appConfig.API_MAIN_URL;

interface ApiResponse<T> {
  data: T;
  error?: string;
}

interface ProfileIdResponse {
  urn: string;
}

export const leadLensApi = {
  generateIcebreaker: async (params: { linkedinId: string; userId: string; token: string }): Promise<ApiResponse<string>> => {
    const { data } = await axios.post(
      `${API_URL}/api/lead-lens/generate-icebreaker`,
      {
        linkedinId: params.linkedinId,
        linkedinProfileId: params.userId,
        userId: params.userId,
      },
      {
        headers: { Authorization: `Bearer ${params.token}` },
      }
    );
    return data;
  },

  syncPostInteractions: async (params: { postId: string; userId: string; token: string }): Promise<ApiResponse<PostReactionsResponse>> => {
    const { data } = await axios.post(
      `${API_URL}/api/lead-lens/sync-post-interactions`,
      {
        postUrn: params.postId,
        linkedinProfileId: params.userId,
        userId: params.userId,
      },
      {
        headers: { Authorization: `Bearer ${params.token}` },
      }
    );
    return data;
  },

  updateIcpSettings: async (params: { userId: string; token: string; data: IcpFormData }): Promise<ApiResponse<unknown>> => {
    const { data } = await axios.put(
      `${API_URL}/api/user-settings`,
      { 
        user_id: params.userId,
        ...params.data 
      },
      {
        headers: { Authorization: `Bearer ${params.token}` },
      }
    );
    return data;
  },

  getProfileIdByUserId: async (params: { userId: string }): Promise<ApiResponse<ProfileIdResponse>> => {
    try {
      const { data } = await axios.get(
        `${API_URL}/api/lead-lens/get-profile-id-by-user-id`,
        { params: { userId: params.userId } }
      );

      return data;
    } catch (error) {
      console.error(error, "[LeadLensApi] Error getting profile id by user id");
      throw error;
    }
  }
};

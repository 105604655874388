import { Badge } from "components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import type { LinkedInProfileData } from "../types";

interface LinkedInProfileDisplayProps {
	profile: LinkedInProfileData;
}

export const LinkedInProfileDisplay = ({
	profile,
}: LinkedInProfileDisplayProps) => {
	if (!profile) return null;

	return (
		<Card>
			<CardHeader>
				<CardTitle>{profile.name}</CardTitle>
				<p className="text-sm text-muted-foreground">{profile.one_liner}</p>
			</CardHeader>
			<CardContent className="space-y-6">
				<div>
					<h4 className="font-medium mb-2">Summary</h4>
					<p className="text-sm">{profile.summary}</p>
				</div>

				<div>
					<h4 className="font-medium mb-2">Top Skills</h4>
					<div className="flex flex-wrap gap-2">
						{profile.top_skills?.map((skill, index) => (
							<Badge key={`${index + 1}`} variant="secondary">
								{skill}
							</Badge>
						))}
					</div>
				</div>

				<div>
					<h4 className="font-medium mb-2">Work Experience</h4>
					<div className="space-y-4">
						{profile.work_experiences?.map((exp, index) => (
							<div key={`${index + 1}`} className="text-sm">
								<p className="font-medium">
									{exp.title} at {exp.company}
								</p>
								<p className="text-muted-foreground">{exp.duration}</p>
								{exp.description && (
									<p className="mt-1 text-muted-foreground">
										{exp.description}
									</p>
								)}
							</div>
						))}
					</div>
				</div>

				<div>
					<h4 className="font-medium mb-2">Education</h4>
					<div className="space-y-4">
						{profile.educations?.map((edu, index) => (
							<div key={`${index + 1}`} className="text-sm">
								<p className="font-medium">{edu.degree}</p>
								<p className="text-muted-foreground">
									{edu.institution || edu.schoolName}
								</p>
								{edu.duration && (
									<p className="text-muted-foreground">
										{edu.duration || `${edu.start?.year} - ${edu.end?.year}`}
									</p>
								)}
							</div>
						))}
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { useEffect, useState } from "react";
import { appConfig } from "views/content-creation/config";

import axios from "axios";
import { type WordPressUser, WordPressUsers } from "./wordpress-users";

export type WordPressCredentialsType = {
	id: number;
	user_id: string;
	organization_id: string;
	username: string;
	application_password: string;
	domain: string;
	domain_id: number;
	shared_with_organization: boolean;
	createdAt: string;
	updatedAt: string;
	roles: string[];
};

export type WordPressCredentialsResponse = {
	success: boolean;
	data?: WordPressCredentialsType;
	message?: string;
};

const fetchWordPressCredentials = async (
	userId: string,
	organizationId: string,
): Promise<WordPressCredentialsType[]> => {
	const { data } = await axios.get("/api/wordpress/get/credentials", {
		params: {
			userId,
			organizationId,
		},
	});
	return data;
};

const addWordPressCredentials = async (
	credentials: {
		domain: string;
		wordpress_username: string;
		wordpress_application_password_key: string;
	},
	userId: string,
	organizationId: string,
	sharedWithOrganization: boolean,
): Promise<WordPressCredentialsResponse> => {
	try {
		const { data } = await axios.post(
			`${appConfig.API_MAIN_URL}/api/wordpress/store-credentials`,
			{
				credentials: {
					domain: credentials.domain,
					wordpress_username: credentials.wordpress_username,
					wordpress_application_password_key:
						credentials.wordpress_application_password_key,
				},
				sharedWithOrganization,
				organizationId,
				userId,
			},
		);
		return data;
	} catch (error) {
		if (axios.isAxiosError(error) && error.response?.data?.message) {
			throw new Error(error.response.data.message);
		}
		throw new Error(
			"Wrong credentials. Check this tutorial for application passwords: https://www.nextsoftwaresolutions.com/kb/create-application-passwords-in-wordpress/",
		);
	}
};

export type WordpressCredentialsState = {
	applicationPassword?: string;
	webUrl?: string;
	username?: string;
	user?: WordPressUser;
	domainId?: number;
	organizationId?: string;
};

type WordPressCredentialsProps = {
	userId: string;
	organizationId: string;
	wordpressConfig: WordpressCredentialsState | null;
	setWordpressConfig: React.Dispatch<
		React.SetStateAction<WordpressCredentialsState | null>
	>;
};

export const WordPressCredentials: React.FC<WordPressCredentialsProps> = ({
	userId,
	organizationId,
	setWordpressConfig,
	wordpressConfig,
}) => {
	const queryClient = useQueryClient();

	const {
		data: credentials,
		isLoading: isLoadingCredentials,
		isError: isCredentialsError,
		error: credentialsError,
	} = useQuery({
		queryKey: ["wordPressCredentials", userId, organizationId],
		queryFn: () => fetchWordPressCredentials(userId, organizationId),
		staleTime: 5 * 60 * 1000,
	});

	const {
		data: defaultUser,
		isLoading: isLoadingDefaultUser,
		isError: isDefaultUserError,
		error: defaultUserError,
	} = useQuery({
		queryKey: ["wordPressUser", userId],
		queryFn: () => fetchWordPressUser(userId),
		enabled: !!credentials?.length,
	});

	useEffect(() => {
		if (defaultUser && credentials?.length) {
			const defaultDomain =
				credentials.find(
					(c) => String(c.domain_id) === String(defaultUser.domain_id),
				)?.domain || "";
			if (defaultDomain) {
				handleSelectChange(defaultDomain);
				setDomain(defaultDomain);
			}
		}
	}, [defaultUser, credentials]);

	const addCredentialsMutation = useMutation({
		mutationFn: (credentials: {
			domain: string;
			wordpress_username: string;
			wordpress_application_password_key: string;
		}) =>
			addWordPressCredentials(
				credentials,
				userId,
				organizationId,
				sharedWithOrganization,
			),
		onSuccess: async (response) => {
			if (response.success) {
				await queryClient.invalidateQueries({
					queryKey: ["wordPressCredentials", userId, organizationId],
				});
				setIsDialogOpen(false);
			} else {
				setErrorMessage(
					response.message || "Error al guardar las credenciales",
				);
			}
		},
		onError: (error: Error) => {
			setErrorMessage(error.message);
		},
	});

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [domain, setDomain] = useState("");
	const [newDomain, setNewDomain] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [sharedWithOrganization, setSharedWithOrganization] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [selectedDomain, setSelectedDomain] = useState<string>("");

	const handleAddCredentials = () => {
		setErrorMessage(null); // Reset error message
		addCredentialsMutation.mutate({
			domain: newDomain,
			wordpress_username: username,
			wordpress_application_password_key: password,
		});
	};

	const handleSelectChange = (value: string) => {
		setSelectedDomain(value);
		const credential = credentials?.find(
			(c) => c?.domain === value && c?.roles?.includes("administrator"),
		);
		if (credential) {
			setWordpressConfig({
				username: credential.username,
				applicationPassword: credential.application_password,
				webUrl: credential.domain,
				domainId: credential.domain_id,
				organizationId: credential.organization_id,
			});
		}
	};

	const fetchWordPressUser = async (
		userId: string,
	): Promise<WordPressUser | null> => {
		const { data } = await axios.get<{ data: WordPressUser[] }>(
			`${appConfig.API_MAIN_URL}/api/wordpress/get-user/${userId}`,
		);

		if (!data.data.length) {
			return null;
		}

		return data.data.reduce((oldest: WordPressUser, user: WordPressUser) => {
			return new Date(user.fetched_at) < new Date(oldest.fetched_at)
				? user
				: oldest;
		});
	};

	if (isLoadingCredentials || isLoadingDefaultUser) {
		return <div>Cargando configuración de WordPress...</div>;
	}

	if (isCredentialsError || isDefaultUserError) {
		return (
			<div className="text-red-500">
				Error al cargar la configuración de WordPress:
				{(credentialsError as Error)?.message ||
					(defaultUserError as Error)?.message}
			</div>
		);
	}

	const uniqueAdminCredentials =
		credentials?.reduce((acc, credential) => {
			if (
				credential?.roles?.includes("administrator") &&
				!acc.some((c) => c?.domain === credential?.domain)
			) {
				acc.push(credential);
			}
			return acc;
		}, [] as WordPressCredentialsType[]) || [];

	return (
		<>
			<h2 className="text-sm font-medium text-muted-foreground">
				Wordpress Config
			</h2>
			<div className="flex flex-col gap-3 w-full sm:flex-row">
				<Select value={selectedDomain} onValueChange={handleSelectChange}>
					<SelectTrigger className="w-[280px]">
						<SelectValue placeholder="Select WordPress Domain" />
					</SelectTrigger>
					<SelectContent>
						{(uniqueAdminCredentials || [])?.map((credential) => {
							if (!credential?.domain) {
								return null;
							}
							return (
								<SelectItem key={credential.domain} value={credential.domain}>
									{credential.domain}
								</SelectItem>
							);
						})}
					</SelectContent>
				</Select>
				<WordPressUsers
					credentials={wordpressConfig}
					defaultUsername={defaultUser?.name}
					setWordPressUser={(user) => {
						setWordpressConfig((prev) => ({
							...prev,
							user,
						}));
					}}
				/>
				<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<DialogTrigger asChild>
						<Button>Add New Credentials</Button>
					</DialogTrigger>
					<DialogContent className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle>Add WordPress Credentials</DialogTitle>
							<DialogDescription>
								Enter your WordPress credentials to add a new connection.
							</DialogDescription>
						</DialogHeader>
						<div className="grid gap-4 py-4">
							{errorMessage && (
								<div className="text-red-500">{errorMessage}</div>
							)}
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="domain" className="text-right">
									Domain
								</Label>
								<Input
									id="domain"
									value={newDomain}
									onChange={(e) => setNewDomain(e.target.value)}
									className="col-span-3"
								/>
							</div>
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="username" className="text-right">
									Username
								</Label>
								<Input
									id="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									className="col-span-3"
								/>
							</div>
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="password" className="text-right">
									Application Password
								</Label>
								<Input
									id="password"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									className="col-span-3"
								/>
							</div>
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="shared" className="text-right">
									Share with organization
								</Label>
								<input
									type="checkbox"
									id="shared"
									checked={sharedWithOrganization}
									onChange={(e) => setSharedWithOrganization(e.target.checked)}
									className="mr-auto"
								/>
							</div>
						</div>
						<DialogFooter>
							<Button type="submit" onClick={handleAddCredentials}>
								Add Credentials
							</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</div>
		</>
	);
};

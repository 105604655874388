import { Card, CardContent } from "components/ui/card";
import { motion } from "framer-motion";
import { cn } from "lib/utils";
import { CheckIcon, ChevronRightIcon } from "lucide-react";

interface Step {
	title: string;
	id: string;
}

interface StepsProps {
	steps: Step[];
	currentStep: number;
}

export function Steps({ steps, currentStep }: StepsProps) {
	return (
		<Card className="mx-auto w-full">
			<CardContent className="p-6">
				<div className="overflow-x-auto">
					<div className="flex justify-between min-w-max">
						{steps.map((step, index) => (
							<div
								key={`step-${step.id}`}
								className="flex flex-col items-center px-4"
							>
								<motion.div
									className={cn(
										"w-10 h-10 rounded-full flex items-center justify-center text-sm font-semibold",
										index <= currentStep
											? "bg-primary text-primary-foreground"
											: "bg-muted text-muted-foreground",
									)}
									initial={false}
									animate={{
										scale: index === currentStep ? 1 : 1,
										transition: { type: "spring", stiffness: 300, damping: 20 },
									}}
								>
									{index < currentStep ? (
										<CheckIcon className="w-5 h-5" />
									) : (
										index + 1
									)}
								</motion.div>
								<div className="mt-2 text-sm font-medium text-muted-foreground">
									{step.title}
								</div>
								{index < steps.length - 1 && (
									<ChevronRightIcon className="mt-2 w-4 h-4 text-muted-foreground" />
								)}
							</div>
						))}
					</div>
					<div className="mt-4 h-2 rounded-full bg-muted">
						<motion.div
							className="h-2 rounded-full bg-primary"
							initial={{ width: "0%" }}
							animate={{
								width: `${(currentStep / (steps.length - 1)) * 100}%`,
							}}
							transition={{ type: "spring", stiffness: 100, damping: 20 }}
						/>
					</div>
				</div>
			</CardContent>
		</Card>
	);
}

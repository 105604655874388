import axios from "axios";
import type { LinkedInPost, SalesDemoResponse } from "../types";

const SALES_DEMO_API = "/api/sales-demo";

interface PostMetrics {
	likes: number;
	comments: number;
	shares: number;
	impressions: number;
}

interface BlogPost {
	linkedin_id: string;
	user_id: string;
	organization_id: string;
	content: string;
	type: string | null;
	metrics: PostMetrics;
	creation_time: string;
	last_lead_sync: string | null;
	totalInteractions: number;
	qualifiedLeads: number;
	url: string;
}

export const fetchLinkedInPosts = async (
	linkedinUrl: string,
): Promise<LinkedInPost[]> => {
	console.log("[SalesDemo] Iniciando fetch de posts", { linkedinUrl });

	try {
		const { data } = await axios.get<{ posts: LinkedInPost[] }>(
			`${SALES_DEMO_API}/posts?linkedinUrl=${encodeURIComponent(linkedinUrl)}`,
			{
				headers: {
					"Content-Type": "application/json",
				},
			},
		);

		console.log("[SalesDemo] Posts obtenidos exitosamente", {
			totalPosts: data.posts.length,
			firstPostId: data.posts[0]?.id,
		});

		return data.posts;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error("[SalesDemo] Error al obtener posts", {
				status: error.response?.status,
				statusText: error.response?.statusText,
				data: error.response?.data,
				message: error.message,
				url: error.config?.url,
			});

			if (error.response?.status === 404) {
				throw new Error(
					"La ruta de la API no existe. Por favor, verifica la configuración.",
				);
			}

			if (error.response?.status === 500) {
				throw new Error(
					"Error interno del servidor. Por favor, inténtalo más tarde.",
				);
			}

			throw new Error(
				error.response?.data?.message ||
					"Error obteniendo posts de LinkedIn. Por favor, inténtalo más tarde.",
			);
		}

		console.error("[SalesDemo] Error inesperado", {
			error:
				error instanceof Error
					? {
							name: error.name,
							message: error.message,
							stack: error.stack,
						}
					: error,
			linkedinUrl,
		});
		throw error;
	}
};

export const generateBlogs = async (data: {
	posts: LinkedInPost[];
	userId: string;
	domainId: string;
}): Promise<SalesDemoResponse> => {
	console.log("[SalesDemo] Iniciando generación de blogs", {
		userId: data.userId,
		domainId: data.domainId,
		totalPosts: data.posts.length,
	});

	try {
		const { data: responseData } = await axios.post<SalesDemoResponse>(
			`${SALES_DEMO_API}/generate`,
			data,
			{
				headers: {
					"Content-Type": "application/json",
				},
			},
		);

		console.log("[SalesDemo] Blogs generados exitosamente", {
			success: responseData.success,
			postsProcessed: responseData.postsProcessed,
		});

		return responseData;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error("[SalesDemo] Error al generar blogs", {
				status: error.response?.status,
				statusText: error.response?.statusText,
				data: error.response?.data,
				message: error.message,
				url: error.config?.url,
			});

			if (error.response?.status === 404) {
				throw new Error(
					"La ruta de la API no existe. Por favor, verifica la configuración.",
				);
			}

			if (error.response?.status === 500) {
				throw new Error(
					"Error interno del servidor. Por favor, inténtalo más tarde.",
				);
			}

			throw new Error(
				error.response?.data?.message ||
					"Error generando blogs. Por favor, inténtalo más tarde.",
			);
		}

		console.error("[SalesDemo] Error inesperado al generar blogs", {
			error:
				error instanceof Error
					? {
							name: error.name,
							message: error.message,
							stack: error.stack,
						}
					: error,
			requestData: {
				userId: data.userId,
				domainId: data.domainId,
				totalPosts: data.posts.length,
			},
		});
		throw error;
	}
};

interface CreateBlogPostSalesDemoRequest {
	profileUrl: string;
	posts: BlogPost[];
	userId: string;
	status?: string;
	domainsToPublish?: string[];
}

interface CreateBlogPostSalesDemoResponse {
	message: string;
}

export async function createBlogPostSalesDemo(
	data: CreateBlogPostSalesDemoRequest,
): Promise<CreateBlogPostSalesDemoResponse> {
	const response = await axios.post("/api/sales-demo/blog-post", data);
	return response.data;
}

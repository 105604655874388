import type { SortingState } from "@tanstack/react-table";
import {
	type OnChangeFn,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { useEffect, useState } from "react";

import { useToast } from "components/hooks/use-toast";
import { TablePagination } from "components/table-pagination";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { Textarea } from "components/ui/textarea";
import { TooltipProvider } from "components/ui/tooltip";
import { useUnipileAuth } from "contexts/unipile-auth.context";
import { useLeadLensEvents } from "../../events";
import type { PostReactionInteraction } from "../../types";
import { LinkedInConnectDialog } from "../linkedin-connect-dialog";
import { createColumns } from "./columns";

export interface LeadsTableProps {
	leads: PostReactionInteraction[];
	onLeadClick: (lead: PostReactionInteraction) => void;
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
	pageSize: number;
	onPageSizeChange: (size: number) => void;
	totalItems: number;
	onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
	onSendMessage: (linkedinId: string, message: string) => Promise<void>;
	syncingProfiles: Set<string>;
	sortBy: string;
	sortDirection: "asc" | "desc";
	onSortChange: (sortBy: string, sortDirection: "asc" | "desc") => void;
}

export const LeadsTable = ({
	leads,
	onLeadClick,
	currentPage,
	totalPages,
	onPageChange,
	pageSize,
	onPageSizeChange,
	totalItems,
	onGenerateIcebreaker,
	onSendMessage,
	syncingProfiles,
	sortBy,
	sortDirection,
	onSortChange,
}: LeadsTableProps) => {
	const events = useLeadLensEvents();
	const unipileAuth = useUnipileAuth();
	const { toast } = useToast();
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: sortBy,
			desc: sortDirection === "desc",
		},
	]);

	const [selectedLead, setSelectedLead] =
		useState<PostReactionInteraction | null>(null);
	const [messageModalOpen, setMessageModalOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [sending, setSending] = useState(false);
	const [showLinkedInConnect, setShowLinkedInConnect] = useState(false);

	useEffect(() => {
		if (selectedLead?.profile.lead?.icebreaker) {
			setMessage(selectedLead.profile.lead.icebreaker);
		}
	}, [selectedLead]);

	useEffect(() => {
		setSorting([
			{
				id: sortBy,
				desc: sortDirection === "desc",
			},
		]);
	}, [sortBy, sortDirection]);

	const handleSortingChange: OnChangeFn<SortingState> = (updatedSorting) => {
		const newSorting =
			typeof updatedSorting === "function"
				? updatedSorting(sorting)
				: updatedSorting;

		setSorting(newSorting);
		if (newSorting.length > 0) {
			const { id, desc } = newSorting[0];
			onSortChange(id, desc ? "desc" : "asc");
		}
	};

	const handleSendMessage = (linkedinId: string) => {
		const lead = leads.find((lead) => lead.profile.linkedinId === linkedinId);
		if (!lead) {
			return;
		}

		setSelectedLead(lead);
		setMessageModalOpen(true);

		events.trackMessageModalOpen({
			linkedin_id: linkedinId,
			first_name: lead.profile.firstName,
			last_name: lead.profile.lastName,
			company: lead.profile.company || "",
			title: lead.profile.title || "",
			message_length: 0,
			has_icebreaker: Boolean(lead.profile.lead?.icebreaker),
		});
	};

	const columns = createColumns({
		syncingProfiles,
		onGenerateIcebreaker,
		onSendMessage: handleSendMessage,
	});

	const handleSend = async () => {
		if (!selectedLead || !message.trim()) {
			return;
		}

		setSending(true);
		events.trackMessageAttempt({
			linkedin_id: selectedLead.profile.linkedinId,
			first_name: selectedLead.profile.firstName,
			last_name: selectedLead.profile.lastName,
			company: selectedLead.profile.company || "",
			title: selectedLead.profile.title || "",
			message_length: message.length,
			has_icebreaker: Boolean(selectedLead.profile.lead?.icebreaker),
		});

		try {
			await onSendMessage(selectedLead.profile.linkedinId, message);

			events.trackMessageSent({
				linkedin_id: selectedLead.profile.linkedinId,
				first_name: selectedLead.profile.firstName,
				last_name: selectedLead.profile.lastName,
				company: selectedLead.profile.company || "",
				title: selectedLead.profile.title || "",
				message_length: message.length,
				has_icebreaker: Boolean(selectedLead.profile.lead?.icebreaker),
			});

			toast({
				title: "Mensaje enviado",
				description: `Mensaje enviado exitosamente a ${selectedLead.profile.firstName} ${selectedLead.profile.lastName}`,
			});

			handleModalClose();
		} catch (error) {
			events.trackMessageFailed({
				linkedin_id: selectedLead.profile.linkedinId,
				first_name: selectedLead.profile.firstName,
				last_name: selectedLead.profile.lastName,
				company: selectedLead.profile.company || "",
				title: selectedLead.profile.title || "",
				message_length: message.length,
				has_icebreaker: Boolean(selectedLead.profile.lead?.icebreaker),
				error: error instanceof Error ? error.message : "Error desconocido",
			});
		} finally {
			setSending(false);
		}
	};

	const table = useReactTable({
		data: leads,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: handleSortingChange,
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting,
		},
	});

	const handleModalClose = () => {
		if (!selectedLead) return;

		events.trackMessageModalClose({
			linkedin_id: selectedLead.profile.linkedinId,
			first_name: selectedLead.profile.firstName,
			last_name: selectedLead.profile.lastName,
			company: selectedLead.profile.company || "",
			title: selectedLead.profile.title || "",
			message_length: message.length,
			has_icebreaker: Boolean(selectedLead.profile.lead?.icebreaker),
		});

		setMessageModalOpen(false);
		setSelectedLead(null);
		setMessage("");
	};

	return (
		<div className="space-y-4">
			<TooltipProvider delayDuration={0}>
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								))}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows.map((row) => (
							<TableRow
								key={row.id}
								className="hover:bg-muted/50 cursor-pointer"
								onClick={() =>
									onLeadClick({
										...row.original,
										postInteraction: row.original.interactions.posts.map(
											(post) => ({
												type: post.type as "like" | "comment" | "share",
												interactionDate: post.interactionDate,
												comment: post.comment,
											}),
										),
									})
								}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TooltipProvider>

			<TablePagination
				currentPage={currentPage}
				totalPages={totalPages}
				onPageChange={onPageChange}
				pageSize={pageSize}
				onPageSizeChange={onPageSizeChange}
				totalItems={totalItems}
			/>

			<Dialog open={messageModalOpen} onOpenChange={handleModalClose}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							{!unipileAuth.isAuthenticated
								? "Conexión de LinkedIn Requerida"
								: `Enviar Mensaje a ${selectedLead?.profile.firstName} ${selectedLead?.profile.lastName}`}
						</DialogTitle>
					</DialogHeader>
					{!unipileAuth.isAuthenticated ? (
						<div className="flex flex-col items-center justify-center py-6 text-center">
							<p className="text-muted-foreground mb-6">
								Para enviar mensajes a tus leads, necesitas conectar tu cuenta
								de LinkedIn primero.
							</p>
							{unipileAuth.error && (
								<p className="text-red-500 mb-4">{unipileAuth.error}</p>
							)}
							<Button
								onClick={() => setShowLinkedInConnect(true)}
								disabled={unipileAuth.isLoading}
							>
								{unipileAuth.isLoading ? "Conectando..." : "Conectar LinkedIn"}
							</Button>
						</div>
					) : (
						<>
							<div className="space-y-4 py-4">
								<Textarea
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									placeholder={`Hola ${selectedLead?.profile.firstName}! Gracias por tu ${selectedLead?.interactions.hasCommented ? "comentario" : "like"} en el último post. Cómo anda todo en ${selectedLead?.profile.company}?`}
									className="min-h-[200px]"
								/>
							</div>
							<DialogFooter>
								<Button variant="outline" onClick={handleModalClose}>
									Cancelar
								</Button>
								<Button
									onClick={handleSend}
									disabled={sending || !message.trim()}
								>
									{sending ? "Enviando..." : "Enviar"}
								</Button>
							</DialogFooter>
						</>
					)}
				</DialogContent>
			</Dialog>

			<LinkedInConnectDialog
				isOpen={showLinkedInConnect}
				onClose={() => setShowLinkedInConnect(false)}
			/>
		</div>
	);
};

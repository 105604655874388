import { Animate, AuthContext } from "components/lib";
import { UnipileAuthProvider } from "contexts/unipile-auth.context";
import { useContext, useEffect } from "react";
import type { AuthContextType } from "types/authContext";
import { LeadTrackingTable } from "./_components/lead-tracking-table";
import { useLeadLensEvents } from "./events";

export const LeadLens = () => {
	const auth: AuthContextType = useContext(AuthContext);
	const events = useLeadLensEvents();

	useEffect(() => {
		if (auth?.user) {
			events.trackPageView({
				user_id: auth.user.id,
				source: window.location.pathname,
			});
		}
	}, [auth?.user]);

	if (!auth?.user) return null;

	return (
		<Animate type="pop">
			<div className="mx-auto max-w-8xl py-6 space-y-4">
				<UnipileAuthProvider>
					<LeadTrackingTable />
				</UnipileAuthProvider>
			</div>
		</Animate>
	);
};
